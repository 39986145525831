import { Link } from "react-router-dom";
import "./not-found.css";

const NotFound = () => {
  return (
    <section className="not-found">
      <div className="center-nf">
        <h1>404</h1>
        <p>The page you are looking for is not available!</p>

        <div className="bg-nf"></div>

        <Link to="/">&#171; Go Back</Link>
      </div>
    </section>
  );
};

export default NotFound;
