import React, { lazy } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.js";

const Header = lazy(() => import("../components/header/header.component"));
const Footer = lazy(() => import("../components/footer/footer.componentt"));
const Blog = lazy(() => import("../components/blog/blog.component.jsx"));


const BlogPage = () => {
    const { id } = useParams();

    return (
      <React.Fragment>
        <Header />
        <main className="main">
            <Blog id={id} />
        </main>
        <Footer />
      </React.Fragment>
    );
  };
  
  export default BlogPage;