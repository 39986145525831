import React, { lazy } from "react";

const Header = lazy(() => import("../components/header/header.component"));
const Footer = lazy(() => import("../components/footer/footer.componentt"));
const BlogsSection = lazy(() => import("../components/blogs-section/blogs-section.component"));


const BlogsPage = () => {
    return (
      <React.Fragment>
        <Header />
        <main className="main">
            <BlogsSection />
        </main>
        <Footer />
      </React.Fragment>
    );
  };
  
  export default BlogsPage;