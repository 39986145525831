import "./supense-loader.css";

const SuspenseLoader = () => {
  return (
    <div className="sl">
      <div className="spinner"></div>
    </div>
  );
};

export default SuspenseLoader;
