import { Route, Switch } from "react-router-dom";
import "./App.css";
import SuspenseLoader from "./components/supense-loader/suspense-loader.component";
import { Suspense } from "react";
import LandingPage from "./pages/landing-page.component";
import BlogsPage from "./pages/blogs-page.component";
import BlogPage from "./pages/blog-page.component";
import NotFound from "./pages/not-found.component";

const App = () => {
  return (
    <Suspense fallback={<SuspenseLoader />}>
      <Switch>
          <Route exact path="/">
            <LandingPage />
          </Route>
          <Route exact path="/blogs">
            <BlogsPage />
          </Route>
          <Route exact path="/blog/:id">
            <BlogPage />
          </Route>
          <Route path="*">
            <NotFound />
          </Route>
      </Switch>
    </Suspense>
  );
};

export default App;
