import { lazy } from "react";

const Header = lazy(() => import("../components/header/header.component"));
const Home = lazy(() => import("../components/home/home.component"));
const About = lazy(() => import("../components/about/about.component"));
const Conditions = lazy(() => import("../components/conditions/conditions.component"));
const ResearchPaper = lazy(() => import("../components/research-paper/research-paper.component"));
const Qualifications = lazy(() => import("../components/qualifications/qualifications.component"));
const ExpertRecommendations = lazy(() => import("../components/testimonials/expert-recommendations.component"));
const Reviews = lazy(() => import("../components/reviews/reviews.component"));
const Contact = lazy(() => import("../components/contact/contact.component"));
const Footer = lazy(() => import("../components/footer/footer.componentt"));
const ScrollUp = lazy(() => import("../components/scroll-up/scroll-up.component"));
const MoreAbout = lazy(() => import("../components/more-about/more-about.component"));
const Affiliations = lazy(() => import("../components/Affiliations/affiliations.component"));
const SurgeriesPerformed = lazy(() => import("../components/surgeries-performed/surgeries-performed.component"));
const PictureGrid = lazy(() => import("../components/picture-grid/picture-grid.component"));

const LandingPage = () => {
  return (
    <>
      <Header />

      <main className="main">
        <Home />
        <About />
        <Conditions />
        <SurgeriesPerformed />
        <ResearchPaper />
        <Qualifications />
        <ExpertRecommendations />
        <Reviews />
        <MoreAbout />
        <Affiliations />
        <Contact />
        <PictureGrid />
      </main>

      <Footer />
      <ScrollUp />
    </>
  );
};

export default LandingPage;
